#root {
	overflow: hidden;
}

body {
	font-family: "Roboto", "Calibri";
}

.screen-content {
	box-sizing: border-box;
	margin-top: 7rem;
	padding: 2rem;
}

.header-menu-overlay {
	background-color: rgba(0, 0, 0, 0.5); 
	height: 100vh;
	left: 0;
	position: absolute; 
	top: 0;
	width: 100vw;
}

.header-title {
	color: rgb(255, 255, 255);
	cursor: pointer;
	mix-blend-mode: normal;
}

.footer {
	bottom: 0;
	height: 2rem;
	position: fixed;
	width: 100vw;
}

.select-link {
	cursor: pointer;
}

.table-container {
	max-height: 60vh;
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
}

.book-thumbnail {
	cursor: pointer;
	max-height: 15rem;
	max-width: 100%;
	transition: max-height 0.3s;
}

.book-thumbnail:hover {
	max-height: 16rem;
}

.book-thumbnail-div {
	height: 16.2rem;
}

.book-cover {
	max-height: 45rem;
	max-width: 100%;
}

.book-highlight-cover {
	cursor: pointer;
	max-width: 100%;
}

.product-description {
	max-height: 26rem;
	overflow-y: auto;
	white-space: pre-wrap;
}

.frosted-background {
	background: rgba(255, 255, 255, 0.008);
	padding: 2rem;
}

.background-glass {
	-webkit-backdrop-filter: blur(2px);
	background-color: rgba(255,255,255,.15);
	backdrop-filter: blur(2px);
}

.background-stars {
	background-color:black;
	background-image:
		radial-gradient(white, rgba(255,255,255,.2) 2px, transparent 40px),
		radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 30px),
		radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 40px),
		radial-gradient(rgba(255,255,255,.4), rgba(255,255,255,.1) 2px, transparent 30px);
	background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
	background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;
}

.background-carbon {
	background:
		linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px,
		linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px,
		linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px,
		linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px,
		linear-gradient(90deg, #1b1b1b 10px, transparent 10px),
		linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
	background-color: #131313;
	background-size: 20px 20px;
}

.background-upholstery {
	background:
		radial-gradient(hsl(0, 100%, 27%) 4%, hsl(0, 100%, 18%) 9%, hsla(0, 100%, 20%, 0) 9%) 0 0,
		radial-gradient(hsl(0, 100%, 27%) 4%, hsl(0, 100%, 18%) 8%, hsla(0, 100%, 20%, 0) 10%) 50px 50px,
		radial-gradient(hsla(0, 100%, 30%, 0.8) 20%, hsla(0, 100%, 20%, 0)) 50px 0,
		radial-gradient(hsla(0, 100%, 30%, 0.8) 20%, hsla(0, 100%, 20%, 0)) 0 50px,
		radial-gradient(hsla(0, 100%, 20%, 1) 35%, hsla(0, 100%, 20%, 0) 60%) 50px 0,
		radial-gradient(hsla(0, 100%, 20%, 1) 35%, hsla(0, 100%, 20%, 0) 60%) 100px 50px,
		radial-gradient(hsla(0, 100%, 15%, 0.7), hsla(0, 100%, 20%, 0)) 0 0,
		radial-gradient(hsla(0, 100%, 15%, 0.7), hsla(0, 100%, 20%, 0)) 50px 50px,
		linear-gradient(45deg, hsla(0, 100%, 20%, 0) 49%, hsla(0, 100%, 0%, 1) 50%, hsla(0, 100%, 20%, 0) 70%) 0 0,
		linear-gradient(-45deg, hsla(0, 100%, 20%, 0) 49%, hsla(0, 100%, 0%, 1) 50%, hsla(0, 100%, 20%, 0) 70%) 0 0;
	background-color: #300;
	background-size: 100px 100px;
}

.background-dotted {
	background:
		radial-gradient(black 15%, transparent 16%) 0 0,
		radial-gradient(black 15%, transparent 16%) 8px 8px,
		radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
		radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
	background-color:#282828;
	background-size:16px 16px;
}

.background-stripes {
	background-color: gray;
	background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, rgba(255,255,255,.5) 35px, rgba(255,255,255,.5) 70px);
}

.book-description {
	overflow-y: auto;
	white-space: pre-wrap;
}

.text-decoration-underline {
	text-decoration: underline;
}

.author-image {
	width: 100%;
}

.header-bar {
	height: 8rem;
}

.navbar-mover {
	z-index: 1;
}

.navbar-item {
	cursor: pointer;
}

.navbar-item:hover {
	background-color: rgba(255, 255, 255, 0.08);
}

@media (min-width: 801px) {
	.book-description {
		max-height: 20rem;
	}

	.navbar-container {
		box-sizing: border-box;
		max-width: 1140px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 15px;
		padding-right: 15px;
		width: 100%;
	}

	.navbar-mover {
		top: -3.25rem;
	}
}

@media (max-width: 800px) {
	.screen-content {
		margin-top: 10rem;
	}
}
